import React, { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactInfo = () => {
	const info = [
		{
			id: 2,
			title: 'Office Address',
			info: [
				'A-11-3A',
				'Jalan Selaman 1/1',
				'68000 Ampang',
				'Selangor, Malaysia',
			],
			image: '/Images/Contact/location-white.svg',
		},
		{
			id: 0,
			title: 'Phone Number',
			info: ['(+60) 11 2331 3110', '(+60) 3 4270 6883'],
			image: '/Images/Contact/contactus-white.svg',
		},
		{
			id: 1,
			title: 'Email Address',
			info: ['contact@mxisolutions.com'],
			image: '/Images/Contact/email-white.svg',
		},
	];
  
	return (
		<div className="section flex justify-center mt-12 pb-20 sdesk1:pb-8 tracking-wider text-black px-10 tab1:px-3">
			<div className="w-full max-w-[1400px] main-border border-2 rounded-xl  outline-2 flex flex-row justify-center">
				<div className="w-full py-24 flex justify-around tab1:py-12 smob1:flex-col smob1:gap-12 flex-wrap gap-y-16">
					{info.map((item) => (
						<div key={item.id} className="wrapper flex gap-6 items-start tab1:flex-col tab1:items-center">
							<div className="image w-24">
								<img src={item.image} alt="item.title"/>
							</div>

							<div className="w-48">
								<div className="title pb-5">
									<span className="font-medium text-xl tab1:text-center tab1:mx-auto tab1:block">{item.title}</span>
								</div>

								<div className="detail flex flex-col gap-1">
									{item.info.map((line, index) => (
										<p key={index} className="text-gray tab1:text-center">
											{line}
										</p>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
  };

const ContactForm = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		website: "",
		message: "",
		'g-recaptcha-response': "",
	});
	const [captchaValue, setCaptchaValue] = useState(null);

	const sendEmail = async (e) => {
		e.preventDefault(); // Prevent default form submission behavior
	
		// Check required fields
		if (!formData.name || !formData.email || !formData.message) {
			alert("Please fill all required fields.");
			return;
		}
	
		// Ensure captcha is validated
		if (!captchaValue) {
			alert("Please complete the reCAPTCHA.");
			return;
		}
	
		try {
			// Update formData with captcha response
			const updatedFormData = { ...formData, "g-recaptcha-response": captchaValue };
		
			await emailjs.send(
				"service_ogsy3le", // Replace with your EmailJS service ID
				"template_ntl8uzk", // Replace with your EmailJS template ID
				updatedFormData,
				"3btdsNoISlzgdQNU1" // Replace with your EmailJS public key
			);
		
			alert("Email sent successfully!");
		
			// Reset form and reCAPTCHA
			setFormData({
				name: "",
				email: "",
				website: "",
				message: "",
				"g-recaptcha-response": "",
			});
			setCaptchaValue(null); // Reset captcha value
		} catch (error) {
			console.error("Error sending email:", error.text);
			alert("Failed to send email. Please try again.");
		}
	};

	const handleCaptchaChange = (value) => {
		setCaptchaValue(value);
	  };

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
  
	return (
		<div className="section flex justify-center max:mb-32 sdesk1:mb-32 tab1:mb-20 mb-20 tracking-wider text-black px-10 tab1:px-3">
			<div className="max-w-[1400px] py-20 px-16 rounded-lg bg-gray-bg-1 flex justify-between tab1:!flex-col tab1:!px-6 tab1:!py-9 tab1:!gap-9">
				<div className="w-[45%] tab1:w-full">
					<div>
						<span className="header-black">
							We'd love to hear from you.
						</span>
					</div>
		
					<div className="pt-4 pb-11 tab1:py-4">
						<p className='paragraph-gray'>
							At MXI Solutions, we help businesses of all sizes harness technology that truly meets their needs and we are committed to providing exceptional customer service and support to all our customers.<br/><br/>Contact us now if you're interested to learn more about any of our services.
						</p>
					</div>
		
					<div>
						{/* <div className="pb-6 font-semibold mob:text-base des:text-lg max:text-2xl">
							<span>Your benefits:</span>
						</div>
			
						<div className="flex flex-wrap gap-x-6 gap-y-4 des:gap-y-2">
							{benefit.map((item) => (
								<span className="flex gap-5 items-center">
									<GoCheckCircle size="26" color="#208BF2" /> <span className='paragraph-gray'>{item.title}</span>
								</span>
							))}
						</div> */}
					</div>
				</div>
		
				<div className="w-1/2 tab1:w-full">
					<form className='h-full' onSubmit={sendEmail}>
						<div className="w-full flex gap-5 pb-6">
							<input
								className="input-short"
								placeholder="Name*"
								name="name"
								value={formData.name}
        						onChange={handleChange}
								required
							></input>
			
							<input
								className="input-short"
								placeholder="Email*"
								name="email"
								value={formData.email}
        						onChange={handleChange}
								required
							></input>
						</div>
			
						<div>
							<input
								className="input-long smob1:py-4"
								placeholder="Website"
								name="website"
								value={formData.website}
        						onChange={handleChange}
							></input>
						</div>

						<div className="py-6 h-[calc(100%-340px)] sdesk1:h-[calc(100%-340px)]">
							<textarea
								className="input-textarea smob1:h-20"
								placeholder="Your Comment*"
								name="message"
								value={formData.message}
        						onChange={handleChange}
								required
							></textarea>
						</div>

						<div className="flex flex-col gap-y-6 mt-4 w-full scale-[1,25]">
							<ReCAPTCHA
							sitekey={'6Ld3HJEqAAAAAMMLFg7Z9k9XaJ0A4YjONn8WkXAl'}
							onChange={handleCaptchaChange}
							/>

							<button
								type="submit"
								className="w-full bg-[#208BF2] py-5 rounded-lg text-white font-medium tracking-wider hover:bg-purple-bg-1"
							>
								Send Message
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
  

export { ContactInfo, ContactForm };
  