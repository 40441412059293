const blogs =[
    {
        title: 'Spreading Our Wings into the Middle East',
        desc: "After successfully completing our first project in the Middle East, in Qatar, " + 
        "we're now exploring more opportunities in the region. We hope with our ability " + 
        "we're able to significantly contribute to the rapidly evolving B2B landscape, helping businesses adapt and thrive in this highly dynamic market",
        img: '/Assets/images/middle-east-news.webp',
        link:'/about',
        date: "01.12.2024",
        cat: 'MXI News'
    },
    {
        title: 'Looking for Strategic Partner for Mutual Growth & Success',
        desc: 'Strategic partner to collaborate on innovative ventures, combining strengths to drive mutual growth, expand market reach, and create lasting value for both parties.',
        img: '/Assets/images/partnership-news.webp',
        link:'/about',
        date: "16.12.2024",
        cat: 'MXI News'
    },
    {
        title: 'First Impression Matter',
        desc: 'With millions of business websites competing for the same customer attention online, first impressions matter more than ever. In a world where attention spans are shrinking, having a website that instantly grabs attention and keeps users engaged is crucial for success',
        img: '/Assets/images/impression-matters.webp',
        link:'/services/web-design',
        date: '11.11.2024',
        cat: 'Our Services'
    },
];

export default blogs;