
import { useState } from 'react';
import { Icon } from '@iconify/react';

// lottie icon
import rocket from '../Icons/rocket.json';
import network from '../Icons/network.json';
import consult from '../Icons/mentorship.json';
import Lottie from 'react-lottie';

import '../Home.css';

import { DoublePurpleDotHeader } from '../../All/PurpleDotHeader';

const ServiceContainer = () => {
    const softwareDevServiceLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: rocket,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    const infraServiceLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: network,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    const consultServiceLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: consult,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    const [hoveredEl, setHoveredEl] = useState({
        consult: false,
        dev: false, 
        integrate: false
    });

    return(
        <div className='service-container desk1:px-20 ssdesk1:px-12 z-20'>
            <div className='max-w-[1400px] flex justify-center flex-wrap mx-auto pt-[112px] relative'>
                <div className='header flex flex-col items-center w-full'>
					<DoublePurpleDotHeader title="SERVICES"/>

                    <div className='header-black tab1:text-center'>
                        Our Journey Starts Here
                    </div>

                    <div className='paragraph-gray text-center des:max-w-[60%] mx-auto'>
                        Choosing the right team for the job is critical. With MXI Solutions, you get more than a service – you get a commitment to digital excellence.
                    </div>
                </div>

                <div className='content mt-12 flex items-start justify-between w-full flex-wrap px-4 smob1:px-0'>
                    <a href='/services/consultation' className='service-wrapper'>
                        <div 
                            className='' 
                            onMouseEnter={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    consult:true
                                })
                            }}
                            onMouseLeave={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    consult:false
                                })
                            }}>
                                <div className='icon'>
                                    <div>
                                        <Lottie options={consultServiceLottieIcon} />
                                    </div>
                                </div>

                                <div className='title'>
                                    Solution Consultation
                                </div>

                                <div className='descr'>
                                    Practical solutions for everyday challenges
                                </div>

                                <div className='btn-wrapper'>
                                    <div className={ hoveredEl.consult ? 'onHover' : '' }>
                                        <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                                    </div>
                                </div>
                        </div>
                    </a>
                    
                    <a href='/services/custom-dev' className='service-wrapper'>
                        <div 
                            className=''
                            onMouseEnter={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    dev:true
                                })
                            }}
                            onMouseLeave={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    dev:false
                                })
                            }}>
                                <div className='icon'>
                                    <div>
                                        <Lottie options={softwareDevServiceLottieIcon} />
                                    </div>
                                </div>

                                <div className='title'>
                                    Software Development
                                </div>

                                <div className='descr'>
                                    Bringing your ideas to life
                                </div>

                                <div className='btn-wrapper'>
                                    <div className={ hoveredEl.dev ? 'onHover' : '' }>
                                        <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                                    </div>
                                </div>
                        </div>
                    </a>
                    
                    <a href='/services/sys-integration' className='service-wrapper'>
                        <div 
                            className=''
                            onMouseEnter={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    integrate:true
                                })
                            }}
                            onMouseLeave={()=> {
                                setHoveredEl({
                                    ...hoveredEl,
                                    integrate:false
                                })
                            }}>
                                <div className='icon'>
                                    <div>
                                        <Lottie options={infraServiceLottieIcon} />
                                    </div>
                                </div>

                                <div className='title'>
                                    System Integration
                                </div>

                                <div className='descr'>
                                    So everything runs together effortlessly.
                                </div>

                                <div className='btn-wrapper'>
                                    <div className={ hoveredEl.integrate ? 'onHover' : '' }>
                                        <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                                    </div>
                                </div>
                        </div>
                    </a>

                </div>

                <a className='goto-services' href='/services'>Learn More</a>
                
            </div>
        </div>
    )
};

export default ServiceContainer;