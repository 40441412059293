import { Link } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { CgFacebook } from 'react-icons/cg'
import { RiTwitterFill } from 'react-icons/ri'
import { BsLinkedin, BsPinterest } from 'react-icons/bs'

const Copyright = () => {
	let year = new Date().getFullYear();
	return (
		<div className="w-full h-28 tab1:h-20 bg-purple-bg-2 flex justify-center items-center">
			<span className="text-white text-base tab1:text-sm font-semibold tracking-wider">
			Copyright {year}. All Rights Reserved
			</span>
		</div>
	);
};

const Information = () => {
	const location = '/Images/Footer/location.svg';
	const workinghours = '/Images/Footer/workinghours.svg';
	const contactus = '/Images/Footer/contactus.svg';

	const cardInfo = [
		{
		id: 0,
		title: 'Location',
		content: [
			'A-11-3A, Jalan Selaman 1/1',
			'Dataran Palma',
			'68000, Ampang',
			'Selangor, Malaysia',
		],
		logo: location,
		alt: 'Location',
		padding: 'flex flex-row items-center py-20 pr-16 sdesk1:pl-10 sdesk1:py-14',
		},
		{
		id: 1,
		title: 'Working Hours',
		content: ['Weekdays 8am - 9pm', 'Weekend 1am - 12pm'],
		logo: workinghours,
		alt: 'Working Hours',
		padding: 'flex flex-row py-20 justify-center items-center sdesk1:pl-10 sdesk1:py-14 sdesk1:justify-normal',
		},
		{
		id: 2,
		title: 'Contact Us',
		content: ['contact@mxisolutions.com', '(+60) 11 2331 3110'],
		logo: contactus,
		alt: 'Contact Us',
		padding: 'flex flex-row items-center py-16 pl-16 sdesk1:pl-10 sdesk1:py-14',
		},
	];

	const link = [
		{ id: 0, title: 'About Us', link: '/about' },
		{ id: 1, title: 'Out Mission', link: '/about' },
		{ id: 2, title: 'Meet The Teams', link: '/' },
		{ id: 3, title: 'Our Projects', link: '/portfolio' },
		{ id: 4, title: 'Contact Us', link: '/contact' },
	];

	const explore = [
		{ id: 0, title: 'What We Offer', link: '/services' },
		{ id: 1, title: 'Offer', link: '/services' },
		{ id: 2, title: 'Our Story', link: '/story' },
		{ id: 3, title: 'Latest Posts', link: '/blog' },
		{ id: 4, title: 'Help Center', link: '/help' },
	];

	const post = [
		{
		id: 0,
		title: 'Spreading Our Wings',
		date: "20241201",
		thumbnail: "/Assets/images/middle-east-news.webp",
		link: '/',
		},
		{
		id: 1,
		title: 'Looking for Strategic Partner',
		date: "20241216",
		thumbnail: "/Assets/images/partnership-news.webp",
		link: '/',
		},
	];

	return (
		<div className="footer w-full bg-purple-bg-1 tracking-wider text-black">
			<div className="aboutus flex flex-col items-center px-9 tab1:px-7 mob1:!px-4">
				<div className="element-wrap flex sdesk1:py-24 sdesk1:gap-16 justify-between justify-items-center pt-32 pb-40 w-full max:w-[1400px]">
					<div className="max-w-sm max1000:max-w-full">
						<div>
							<span className="font-bold text-2xl text-white">About Us</span>

							<p className="pt-5 pb-16 text-lgray font-light tab1:w-[80%]">
								An IT consultancy can help you assess your technology needs and
								develop a technology strategy that aligns with your business
							</p>

							<div className="flex flex-row gap-4">
								<Link className="xs-clbtn-purple" to="https://www.x.com">
									<RiTwitterFill/>
								</Link>

								<Link className="xs-clbtn-purple" to="https://www.facebook.com">
									<CgFacebook />
								</Link>

								<Link className="xs-clbtn-purple" to="https://www.linkedin.com">
									<BsLinkedin/>
								</Link>

								<Link className="xs-clbtn-purple" to="https://www.pinterest.com">
									<BsPinterest/>
								</Link>
							</div>
						</div>
					</div>

					<div className='footer-child flex w-[60%] tab1:w-full smob1:flex-col stab1:gap-y-16 smob1:gap-16 justify-between flex-wrap'>
						<div className="max-w-xs">
							<div>
								<span className="font-bold text-2xl text-white tracking-wider">
									Link
								</span>
							</div>

							<div className="pt-5 text-lgray flex flex-col font-light gap-4">
								{link.map((item) => (
									<div key={item.id}>
										<Link to={item.link} className="transition duration-75 ease-in hover:text-white">
											{item.title}
										</Link>
									</div>
								))}
							</div>
						</div>

						<div className="max-w-xs">
							<div>
								<span className="font-bold text-2xl text-white tracking-wider">
									Explore
								</span>
							</div>

							<div className="pt-5 text-lgray flex flex-col font-light gap-4">
								{explore.map((item) => (
									<div key={item.id}>
										<Link to={item.link} className="transition duration-75 ease-in hover:text-white">
											{item.title}
										</Link>
									</div>
								))}
							</div>
						</div>

						<div className="max-w-xs">
							<div>
								<span className="font-bold text-2xl text-white tracking-wider">
									Recent Posts
								</span>
							</div>

							<div className="pt-5 flex flex-col gap-4">
								{post.map((item) => (
									<Link
										to={item.link}
										key={item.id}
										className="flex flex-row gap-6 p-3 smob1:!px-0 rounded-lg group overflow-hidden transition duration-75 ease-in hover:bg-purple-bg-2"
									>
										<div className="block h-[61px] !w-[61px] aspect-square overflow-hidden rounded-md">
											<img src={item.thumbnail} alt={item.title} className='object-cover w-full h-full'/>
										</div>

										<div className='max-w-[200px] text-white group-hover:text-gray'>
											<div>
												<span className="flex gap-2 items-center text-lgray">
													<FaRegCalendarAlt size="16" color="#E6E6E6" />
													{item.date}
												</span>
											</div>

											<div className='overflow-hidden truncate'>
												<span className="text-white font-light truncate duration-75 ease-in group-hover:text-gray">
													{item.title}
												</span>
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="details flex items-center justify-center border-y border-divide-border">
				<div className="card-wrap grid grid-cols-3 divide-x divide-divide-border outline-1 max-w-[1400px] sdesk1:grid-cols-1 sdesk1:divide-x-0 sdesk1:divide-y sdesk1:w-full">
					{cardInfo.map((item, i) => (
						<div key={item.id} className={item.padding}>
							<div className="image-container !w-20 sdesk1:w-24">
								<img src={item.logo} alt={item.alt} className='w-full'/>
							</div>

							<div className="details pl-4 pt-5 text-base flex flex-col items-start">
								<div className="pb-3">
									<span className="text-white font-semibold">{item.title}</span>
								</div>

								<div>
									<div>
										{item.content.map((line, index) => (
											<p key={index} className="text-lgray font-light">
												{line}
											</p>
										))}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
 
export {Copyright, Information};