import { DoublePurpleDotHeader } from '../../All/PurpleDotHeader';

import Marquee from "react-fast-marquee";

// Our clients section
const clientsData = [{
    name: 'Tabung Haji',
    imgpath: '/Assets/images/clients/th.webp',
},{
    name: 'QNA',
    imgpath: '/Assets/images/clients/qna.webp',
},{
    name: 'UXERA',
    imgpath: '/Assets/images/clients/uxera.webp',
},{
    name: 'BERNAMA NEWS',
    imgpath: '/Assets/images/clients/bnc.webp',
},{
    name: 'HD TECHNOLOGY',
    imgpath: '/Assets/images/clients/hdtech.webp',
},{
    name: 'JR-STITCH',
    imgpath: '/Assets/images/clients/jr-stitch.webp',
},{
    name: 'GLITZ DESIGN',
    imgpath: '/Assets/images/clients/avamoony.webp',
}];

const Client = (props) => {
    return(
        <div className='client'>
            <div className='logo w-12 h-12 mr-3 rounded-[50%] overflow-clip' >
                <img className="w-full h-full object-cover" src={ props.data.imgpath } alt=""/>
            </div>
            <div className='name font-bold text-xl truncate uppercase'>
                { props.data.name }
            </div>
        </div>
    )
};

const ClientContainerOLD = () => {
    const clientHtml = [];

    for(let i=0; i < clientsData.length; i++){
        clientHtml.push(<Client key={i} data={clientsData[i]} />);
    };

    return(
        <div className='clients-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto pb-40 relative'>

                <div className='header flex flex-col items-left w-full'>
					<DoublePurpleDotHeader title="OUR CLIENTS"/>

                    <div className='header-black text-left'>
                        Trusted and Happy Clients
                    </div>
                </div>

                <div className='content-wrapper'>
                    {clientHtml}
                </div>

            </div>
        </div>
    )
}; 
const ClientMarquee = () => {
    const clientHtml = [];

    for(let i=0; i < clientsData.length; i++){
        clientHtml.push(<Client key={i} data={clientsData[i]} />);
    };
    
    return(
        <Marquee 
            speed={70}>
            {clientHtml}
        </Marquee>
    )
}

const ClientContainer = () => {
    return(
        <div className='clients-container section'>
            <div className='clients-wrapper max-w-[1400px] px-20 flex items-center flex-wrap mx-auto pb-40 relative'>

                <div className='header flex flex-col items-left w-full'>
					<DoublePurpleDotHeader title="OUR CLIENTS"/>

                    <div className='header-black text-left'>
                        Trusted and Happy Clients
                    </div>
                </div>

                <div className='content-wrapper'>
                    <ClientMarquee />
                </div>

            </div>
        </div>
    )
}

export {
    ClientMarquee,
    ClientContainerOLD,
    ClientContainer 
}