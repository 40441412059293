import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/All/Header'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'

import { Project, projectsData } from '../../Components/Home/Child/projects'

import './Portfolio.css';

const Portfolio = () => {
    
    const portfolio = projectsData;

    const [isHovering, setIsHovering] = useState({});


    // defining project component states
    if(Object.keys(isHovering).length < 1){
        let projectStates = {...isHovering};
    
        for(let i=0; i < portfolio.length; i++){
            projectStates[portfolio[i].id] = false;
        }

        setIsHovering({...projectStates});
    }

    // handle mouse-in
    const handleMouseOver = (id) => {
        let copyObject = {...isHovering};
        copyObject[id] = true;
        setIsHovering({...copyObject});
    };

    // handle mouse-out
    const handleMouseOut = () => {
        let copyObject = {...isHovering};
        Object.keys(copyObject).forEach(v => copyObject[v] = false);
        setIsHovering({...copyObject});
    };

    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 8
    const lastIndex = currentPage * recordsPerPage
    const firstIndex = lastIndex - recordsPerPage
    const records = portfolio.slice(firstIndex, lastIndex)
    const npage = Math.ceil(portfolio.length / recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    return (
        <div className="projects-page pb-36 tab1:pb-20">
            <Header title="Our Previous Work" name="Projects"/>

            <div className='projects-container flex justify-center pb-28 pt-12' id="project">
                <div className='flex flex-wrap max-w-[1400px] w-full justify-left mob1:px-3'>
                    {records.map((item) => (
                        <div className='cell-container'>
                            <Link to="/portfolio" className='w-full flex rounded-xl border overflow-hidden text-white hover:bg-purple-bg-1 transition-all duration-75 ease-in'>
                                <Project key={item.id} data={item} isHovering={isHovering} handleHover={() => handleMouseOver(item.id)} quitHover={ ()=> handleMouseOut() } />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pagination-container flex items-center justify-center w-full ">
                <div className="grid grid-flow-col grid-cols-auto gap-4">
                    <div>
                        <a href="#project" className="l-clbtn-purple" onClick={prePage}><AiOutlineLeft/></a>
                    </div>

                    {numbers.map((n, i) => (
                        <div className="" key={i}>
                            <a href="#project" className={n === currentPage ? "l-clbtn-purple active" : "l-clbtn-purple"} onClick={() => changeCPage(n)}>{n}</a>
                        </div>
                    ))}

                    <div>
                        <a href="#project" className="l-clbtn-purple" onClick={nextPage}><AiOutlineRight/></a>
                    </div>
                </div>
            </div>
        </div>
    );

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function changeCPage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default Portfolio;