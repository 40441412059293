import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { 
  Autoplay, 
  EffectFade, 
  Pagination, 
  Navigation
} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function ReviewSlider({ data, slidesPerView }) {
  let sliderData = [];
  for (let i = 0; i < data.length; i++) {
    sliderData.push(
      <SwiperSlide key={i}>
            <div className='review-wrapper w-[95%] pt-12 pb-0 bg-white'>
                <div className='message px-12 pb-12'>
                    {data[i].message}
                </div>
                <div className='details bg-[#F3F3F3] px-12 py-8 flex items-center'>
                    <div className='image-wrapper w-16 h-16 bg-[#D9D9D9] overflow-clip mr-4'>
                        <img className='w-full h-full object-cover'src="/Assets/images/image-placeholder.png" alt={data[i].name}/>
                    </div>
                    <div className='person-detail'>
                        <span className='text-2xl text-black font-medium'>{data[i].name}</span><br/>
                        <span className='text-base text-purple-pri'>{ data[i].company}</span>
                    </div>
                </div>
            </div>
      </SwiperSlide>
    );
  }

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={slidesPerView}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        stopOnLastSlide: true,
      }} 
      pagination={true}
      className={'home-banner w-full !pb-24'}
      breakpoints={{
        0:{
          slidesPerView:1,
          spaceBetween:0
        },
        781:{
          slidesPerView:2,
          spaceBetween:10
        }
      }}
      modules={[EffectFade, Autoplay, Pagination, Navigation]}
    >
      {sliderData}
    </Swiper>
  );
}

export function ProjectCarousel({ data, carouselSwiperOption }) {
  let sliderData = [];
  const marqueeRef = useRef(null);
  const scrollTween = useRef(null);

  // Timeout for detecting when manual scroll ends
  const scrollTimeout = useRef(null);

  useGSAP(() => {
    const marquee = marqueeRef.current;

    scrollTween.current = gsap.to(marquee, {
      scrollLeft: (marquee.scrollWidth / 2)+ 24, // Scroll to the end of the content
      duration: 30, // Duration of the scroll animation
      ease: "none",
      repeat: -1, // Infinite loop
      paused: false // Start scrolling automatically
    });
  } , []);

  // Handle mouse enter to pause the automatic scroll
  const handleMouseEnter = () => {
    scrollTween.current.pause();
  };

  // Handle mouse leave to resume the automatic scroll
  const handleMouseLeave = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current); // Clear the timeout if user stops scrolling manually
    }
    
    scrollTween.current.play();
  };


  // Handle wheel scroll to allow manual horizontal scrolling
  const handleWheelScroll = (e) => {
    e.preventDefault();
    if (marqueeRef.current) {
      if(Math.abs(e.deltaX) === 0){
        // Adjust this value to change scroll speed
        marqueeRef.current.scrollLeft = e.deltaY > 0 ? 
        marqueeRef.current.scrollLeft + 60.00 : marqueeRef.current.scrollLeft - 60.00; 
  
        // Pause GSAP animation during manual scroll
        scrollTween.current.pause();
  
        // Update GSAP target to continue from the current scroll position
        gsap.to(marqueeRef.current, { scrollLeft: marqueeRef.current.scrollLeft + 150 });
  
        if(parseFloat(marqueeRef.current.scrollLeft/marqueeRef.current.scrollWidth) >= 0.79){
          e.stopImmediatePropagation()
          window.scrollBy(0,50)
        }
      }
      
    }
  };

  if (data !== undefined) {
    for (let i = 0; i < data.length; i++) {
      sliderData.push(
        <SwiperSlide key={i}>
          { data[i] }
        </SwiperSlide>
      );
    }

    return (
      <div 
        className='scrolled-projects flex overflow-x-scroll overflow-y-clip whitespace-nowrap'
        ref={marqueeRef}
        onMouseEnter={(e)=>{ 
          handleMouseEnter(); 
          e.currentTarget.addEventListener('wheel', handleWheelScroll, { passive: false });
        }}
        onMouseLeave={(e) => { 
          handleMouseLeave();
          e.currentTarget.removeEventListener('wheel', handleWheelScroll);
        }}>  
          {sliderData}
          {sliderData}
      </div>
    );
  } else {
    return <span>Currently we dont have any sale :</span>;
  }
}