import { useState } from 'react';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import services from "../Data/ServiceList";

import Lottie from 'react-lottie';

import "./menu.css"

const Menu = () => {
	const getStateObject = (arr) =>{
		let rtnObject = {}
		arr.forEach(el=> {
			rtnObject[el["id"]] = false			
		});
		return rtnObject;
	} 

	const [hoveredEl, setHoveredEl] = useState(getStateObject(services));

	return (
		<div className="section service-container flex justify-center pb-32 pt-6 px-20 ssdesk1:px-12 stab1:px-8 smob1:px-4">
			<div className="max:w-[1400px] pb-0 flex justify-between flex-wrap stab1:!px-0 smob1:!px-0 sdesk1:!pt-6">
				{services.map((items, key) => (
					<div 
						className="service mb-8" 
						key={key}
						onMouseEnter={()=>{
							setHoveredEl({
                                ...hoveredEl,
                                [items.id]:true
                            })
						}}
						onMouseLeave={()=>{
							setHoveredEl({
                                ...hoveredEl,
                                [items.id]:false
                            })
						}}>
						<Link to={`/services/${items.id}`} className="">
							<div className="servicepage-wrapper">
								<div className='relative'>
									<div className="icon w-20 h-20 rounded-xl overflow-clip border-2 border-gray-300">
										{
											(items.video !== undefined) && (items.video !== '') ?
											<div className='w-full h-full' dangerouslySetInnerHTML={{
												__html: 
													"<video class=\"w-full h-full object-cover top-0\" id=\"bannerVideo\" preload=\"auto\" playsinline autoplay muted loop>" +
													"<source src=" + items.video +" type=\"video/webm\" />" +
													"</video>"
											}}/>
											:
											''
										}
										{
											(items.lottieIcon !== undefined) && (items.lottieIcon !== '') ?
												<div className={items.lottieIcon.className}>
													<Lottie options={items.lottieIcon.path} />
												</div>
												:
												''
										}
										
										{/* <img className="object-cover h-full w-full" src={items.img} alt="hehe" /> */}
									</div>

									<div className="title text-2xl py-6 ellipsis overflow-hidden">
										{items.title}
									</div>

									<p className="descr pb-14 mb-14 ellipsis overflow-hidden">
										{items.descr}
									</p>
								</div>

								<div className="btn-wrapper absolute left-[64px] bottom-[48px]">
									<div className={ hoveredEl[items.id] ? 'onHover' : '' }>
										<Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
									</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
}
export default Menu;