import { Link } from "react-router-dom";
import {PurpleDotHeaderWhite} from "../../All/PurpleDotHeader";

const Contact = () => {
	return (
		<div className="marketing-container flex relative justify-center">
			<div className="inner-child absolute block bottom-[-14px] rotate-[-1deg] w-[110vw] h-[50px]">
			</div>

			<div className="content-wrap">
				<div className="descr-wrap">
					<PurpleDotHeaderWhite title="MAXIMISE CONVERSION"></PurpleDotHeaderWhite>
					
					<h6 className="main-title block text-5xl font-bold pt-8 text-white">
						Increase conversion by up to 400 % !
					</h6>

					<p className="paragraph-white mt-6 smob1:text-justify">
						Statistics reported by Mike Gualtieri from Forrester, 
						a well-designed UI can boost a website's conversion rate by up to <span className="underline">200%</span><br/><br/>
						But that's not all, an enhanced UX design can take this a step further, 
						potentially increasing conversion rates by up to a staggering <span className="font-black">400%.</span>
					</p>

					<div className="btn-wrapper mt-16 smob1:mt-12">
						<Link className="cta-btn" to="/contact"> Learn More</Link>
					</div>
				</div>

				<div 
					className="img-wrap relative"
					dangerouslySetInnerHTML={{
						__html: 
						"<video class=\"w-full h-full object-cover top-0\" id=\"bannerVideo\" preload=\"auto\" playsinline autoplay muted loop>" +
							"<source src=\"/Assets/videos/fast-480p-6s.webm\" type=\"video/webm\" />" +
						"</video>" +
						"<img class=\"absolute z-10 object-contain w-full h-full top-0 bottom-0 my-auto\" src=\"/Assets/images/banner/legacy_text.png\" alt=\"Contact Us\"/>"
					}}/>
			</div>
		</div>
	);
}
export default Contact;