import { FiClock } from 'react-icons/fi'

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP, ScrollTrigger);

const WorkingHours = () => {

	useGSAP(() => {
		const sections = gsap.utils.toArray(".section");

		sections.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
				scrollTrigger: {
					trigger: section,
					once:'true',
					start: "top 80%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 20%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none reverse", // Play on enter, reverse on leave
					// markers: true // Optional: show markers for debugging
				}
			})
		})
	});

	return (
		<div className="workingHInfo-container rounded-[30px] bg-gray-bg-3 p-[12%]">
			<div className="mb-8">
				<span className="title text-3xl font-medium tracking-wide">Working Hours</span>
			</div>

			<div className="hourInfo-container">
				<div className="flex flex-row gap-4 items-center text-lgray1 bg-white p-6 rounded-[12px] mt-4">
					<FiClock size="20"/>
					<span className="datetime text-base font-semibold tracking-wider">
						Monday - Friday 0800 - 1900
					</span>
				</div>

				<div className="flex flex-row gap-4 items-center text-lgray1 bg-white p-6 rounded-[12px] mt-4">
					<FiClock size="20"/>
					<span className="datetime text-base font-semibold tracking-wider">
						Saturday - Sunday Closed
					</span>
				</div>
			</div>
		</div>
	);
}

export default WorkingHours;