import { Icon } from '@iconify/react';
import { useState } from 'react';

import { DoublePurpleDotHeader } from '../../All/PurpleDotHeader';

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP, ScrollTrigger);

const TeamMemberContainer = () => {
    useGSAP(() => {
		const sections = gsap.utils.toArray(".member-wrapper");
        const parentSection = document.querySelector(".TeamMember-container");

		sections.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
                duration: 1, // Duration for each element
                stagger: {
                    amount: 1.5,
                    each: 0.5,
                    ease: "power2"
                },
				scrollTrigger: {
					trigger: parentSection,
                    once: true,
					start: "top 80%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 20%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none reverse", // Play on enter, reverse on leave
					// markers: true, // Optional: show markers for debugging
                    ease: "bounce" // Easing function for smoothness
				}
			})
		})
	});

    const [isHovering, setIsHovering] = useState({
        'one': false,'two': false,'three': false,'four': false,'five': false
    });

    const [isHovered, setIsHovered] = useState(false); 

    const handleMouseOver = (e, param) => {
        if(!isHovered){
            if(param === 'one'){
                setIsHovering({ ...isHovering, "one":true });
            } else if(param === 'two'){
                setIsHovering({ ...isHovering, "two":true });
            } else if(param === 'three'){
                setIsHovering({ ...isHovering, "three":true });
            } else if(param === 'four'){
                setIsHovering({ ...isHovering, "four":true });
            } else if(param === 'five'){
                setIsHovering({ ...isHovering, "five":true});
            }

            setIsHovered(true)
        }  
    };

    const handleMouseOut = (e, id) => { 
        if(isHovered){
            setIsHovering({
                ...isHovering, 
                'one': false,
                'two': false,
                'three': false,
                'four': false,
                'five': false
            });

            setIsHovered(false)
        }
        
    };

    const members = [
        // {
        //     id:'one',
        //     name: 'Muniyati Muhamad',
        //     position: 'CEO',
        //     thumbnail: {
        //         default: '/Assets/images/image-placeholder.png'
        //     } 
        // },
        {
            id:'three',
            name: 'Nik Aqim',
            position: 'CTO/Co-Founder',
            thumbnail: {
                default: '/Assets/images/image-placeholder.png'
            } 
        },
        {
            id:'two',
            name: 'Rashila Asib',
            position: 'Funding Manager',
            thumbnail: {
                default: '/Assets/images/image-placeholder.png'
            } 
        },
        
        {
            id:'four',
            name: 'Dzikrullah',
            position: 'Software Engineer',
            thumbnail: {
                default: '/Assets/images/image-placeholder.png'
            } 
        },
        {
            id:'five',
            name: 'Iqram Kuan',
            position: 'Software Engineer',
            thumbnail: {
                default: '/Assets/images/image-placeholder.png'
            } 
    }];

    const MemberContainer = ({isHovering, data }) => {
        return(
            <div 
                className={`expert-wrapper relative group`}>

                    <div className='image-wrapper w-full h-full'>
                        <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png' alt='oiiaooiiai'/>
                    </div>

                    <div className={`hover-el absolute bottom-[5%] w-[90%] h-fit right-0 left-0 text-center p-5 mob1:p-2 bg-white mx-auto rounded-xl transition-opacity opacity-0 group-hover:opacity-100 group-active:opacity-100 overflow-scroll`}>
                        <span className='text-2xl mob1:text-lg text-purple-pri font-semibold'>{data.name}</span><br/>
                        <span className='text-base mob1:text-sm'>{data.position}</span>
                        <div className='socmed-wrapper mt-4 p-3 mob1:p-2 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between gap-y-1'>
                            <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center cursor-pointer opacity-100 transition-all ease-in duration-100 hover:bg-opacity-75'>
                                <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                            </div>

                            <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center cursor-pointer opacity-100 transition-all ease-in duration-100 hover:bg-opacity-75'>
                                <Icon className="text-xl text-white" icon="uil:twitter" />
                            </div>

                            <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center cursor-pointer opacity-100 transition-all ease-in duration-100 hover:bg-opacity-75'>
                                <Icon className="text-xl text-white" icon="uil:linkedin" />
                            </div>

                            <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center cursor-pointer opacity-100 transition-all ease-in duration-100 hover:bg-opacity-75'>
                                <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                            </div>
                        </div>

                    </div>
            </div>
    )};

    return(
        <div className='TeamMember-container'>
            <div className='max-w-[1400px] px-8 flex items-center flex-wrap mx-auto py-36 smob1:!pt-20 smob1:!pb-16 relative'>

                <div className='flex flex-col items-center w-full'>
					<DoublePurpleDotHeader title="MEMBERS" />

                    <div className='header-black py-7 text-center w-[60%] tab1:w-[90%]'>
                        Team with a big heart and tunnel vision.
                    </div>
                </div>

                <div className='content-wrapper w-full mt-20 stab1:mt-8 smob1:mt-7 flex flex-wrap justify-around items-center' >
                    {
                        members.map((member) => {
                           return (
                           <div 
                                className='member-wrapper'
                                key={member.id}
                                onMouseEnter={(e)=> {handleMouseOver(e, member.id)}} 
                                onMouseLeave={(e)=> {handleMouseOut(e, member.id)}}>
                                    <MemberContainer 
                                        isHovering={isHovering} 
                                        data={member} />
                            </div>)
                        })
                    }

                </div>

            </div>
        </div>
    )
};

export default TeamMemberContainer;