import { useState } from "react";
import { BsArrowDown, BsArrowRight } from 'react-icons/bs'

const Question = ({item}) => {
	const [display, setDisplay] = useState(false)

	const baseHeaderClass = 'p-6 rounded-[16px] flex flex-row justify-between items-center hover:cursor-pointer'
	const headerClass = display ? 'faq-title bg-purple-bg-2 rounded-b-none ' + baseHeaderClass : 'faq-title bg-gray-bg-3 ' + baseHeaderClass

	const baseHeaderTextClass = 'faq-title font-bold tracking-wider text-xl'
	const headerTextClass = display ? 'faq-title text-white ' + baseHeaderTextClass : 'faq-title text-black ' + baseHeaderTextClass

	return (
		<div className="faq w-full py-3 smob1:text-base">
			<div
				onClick={() => setDisplay(!display)}
				className={`transition-colors duration-300 ${headerClass}`}
			>
				<span className={headerTextClass}>{item.question}</span>

				{display ? <BsArrowDown size="20" color="#fff"/> : <BsArrowRight size="20" color="#208BF2"/>}
			</div>

			<div className={`bg-gray-bg-3 text-lgray1 text-xl leading-relaxed rounded-b-[16px] transition-transform duration-500 ${display ? 'opacity-100 visible px-11 max-h-fit py-5 translate-y-0' : 'opacity-0 max-h-0 invisible translate-y-[-32px]'}`} dangerouslySetInnerHTML={{ __html: item.answer }}>
			</div>
		</div>
	);
}
export default Question;