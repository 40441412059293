import { Link } from "react-router-dom";
import { BsArrowRight } from 'react-icons/bs';

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ServicesList = ({services}) => {
	useGSAP(() => {
		const sections = gsap.utils.toArray(".section");

		sections.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
				scrollTrigger: {
					trigger: section,
					once:'true',
					start: "top 80%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 20%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none reverse", // Play on enter, reverse on leave
					// markers: true // Optional: show markers for debugging
				}
			})
		})
	});

	return (
		<div className="servicesInfo-container rounded-[30px] bg-gray-bg-3 px-[12%] py-[12%] mb-7 tab1:mb-6">
			<div className="header-wrap mb-4">
				<span className="title text-3xl font-medium tracking-wide">All Services</span>
			</div>

			<div className="servicesList-wrap">
				{services.map((item, key) => (
					<Link to={ '/services/' + item.link} key={key} className="flex flex-row justify-between w-full py-3 border-b border-divide-border-gray text-lgray1 hover:text-black hover:border-divide-border-l">
						<span className="name text-lg">{item.name}</span>
						<BsArrowRight className="icon" size="20" color="#8E8E8E"/>
					</Link>
				))}
			</div>
		</div>
	);
}

export default ServicesList;