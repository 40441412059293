import './counter.css';

const SquareBoxCounter = ({current, limit}) => {
    const Counter = new Array(limit).fill(true); 
    return(
        <div className='parent-container'>
            <div className='square-count-container'>
                <div className='descr'>
                    <span>{limit - current} spaces left</span>
                </div>
                <div className='icon'>
                    {
                        Counter.map((item, key) => (
                            <div key={key} className={`square-box ${ key < current ? 'active' : ''}`}></div>
                        ))
                    }
                </div>
                
                
            </div>
        </div>
        
    )
}

export default SquareBoxCounter