import { Icon } from '@iconify/react'

import { DoublePurpleDotHeader } from '../../All/PurpleDotHeader';

// Blogs section
const BlogData = [{
    date: "20241201",
    category: "MXI News",
    title: "Spreading Our Wings into the Middle East",
    paragraph: "After successfully completing our first project in the Middle East, in Qatar, " + 
    "we’re now exploring more opportunities in the region. We hope with our ability " + 
    "we're able to significantly contribute to the rapidly evolving B2B landscape, helping businesses adapt and thrive in this highly dynamic market",
    bannerImg: "/Assets/images/middle-east-news.webp"
},{
    date: "20241216",
    category: "MXI News",
    title: "Looking for Strategic Partner for Mutual Growth & Success",
    paragraph: "Strategic partner to collaborate on innovative ventures, combining strengths to drive mutual growth, expand market reach, and create lasting value for both parties",
    bannerImg: "/Assets/images/partnership-news.webp"
}];

const Blog= (props) => {
    let date = props.data.date;
    let dateObj = new Date();
    let year = date.slice(0,4);
    let mon = date.slice(4,6);
    let day = date.slice(6,8);

    dateObj.setFullYear(parseInt(year));
    dateObj.setMonth(parseInt(mon-1));
    dateObj.setDate(parseInt(day));

    return(
        <div className='blog-container h-[675px]'>
            <div className='banner-wrapper w-full h-[275px]'>
                <img className="w-full h-full object-cover rounded-t-xl" src={props.data.bannerImg} alt="Blog"/>
            </div>

            <div className='content-wrapper pt-16 pb-24 px-8'>
                <div className='metadata-wrapper flex items-center font-mono text-xs'>
                    <div className='date flex items-center'>
                        <div className='icon text-inherit'>
                            <Icon icon="bx:calendar" />
                        </div>
                        <div className='value text-lgray1 text-xs contents'>
                            { dateObj.toDateString().split(' ').slice(1).join(' ') }
                        </div>
                    </div>
                    <div className='category text-sm flex items-center'>
                        <div className='icon text-inherit'>
                            <Icon icon="material-symbols:folder-open-outline" />
                        </div>
                        <div className='value capitalize text-lgray1 text-inherit text-xs contents'>
                            { props.data.category }
                        </div>
                    </div>
                </div>

                <div className='descr-wrapper'>
                    <div className='title text-2xl font-semibold pt-2 pb-3'>
                        { props.data.title }
                    </div>
                </div>

                <div className='read-btn text-ellipsis text-base text-lgray1 text-justify'>
                    { props.data.paragraph }
                </div>
            </div>
        </div>
    )
};

const BlogsContainer = () => {
    const allblogs = [];
    
    for(let i=0; i < BlogData.length; i++){
        allblogs.push(<Blog key={i} data={BlogData[i]} />)
    }

    return(
        <div className='blogs-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto pb-36 smob1:pb-20 relative'>

                <div className='flex items-center flex-col w-full'>
					<DoublePurpleDotHeader title="NEWS POSTS"/>

                    <div className='header-black text-center'>
                        Latest News Updates
                    </div>
                </div>

                <div className='content-wrapper w-full mt-20 flex flex-wrap justify-between items-center smob1:px-0'>
                    {allblogs}
                </div>

            </div>
        </div>
    )
};

// end of Blogs section

export { Blog, BlogsContainer}