import Header from "../../Components/All/Header";
import { Hero , Testimonial} from "../../Components/About";
import { 
	StrongPointContainer, 
	TeamMemberContainer, 
	ContactUsCTA2, 
	ClientContainer 
} from "../../Components/Home";

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP, ScrollTrigger);

const About = () => {
	useGSAP(() => {
		const sections = gsap.utils.toArray(".about-section");

		sections.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
				scrollTrigger: {
					trigger: section,
					once:'true',
					start: "top 80%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 20%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none reverse", // Play on enter, reverse on leave
					// markers: true // Optional: show markers for debugging
				}
			})
		})
	});

	return (
			<div className="aboutus-page">
				<Header title="About us"></Header>
				<Hero />
				<StrongPointContainer />
				<ContactUsCTA2 />
				<TeamMemberContainer />
				<Testimonial />
				<ClientContainer />
				
			</div>
		);
	}
export default About;