// project section
const projectsData = [{
    id:"1",
    name:"Editorial System",
    category:"WebApp Development",
    description: "An end-to-end editorial system for news agencies in Qatar.",
    img: {
        default:'/Assets/images/projects/Editor.webp', 
        classpath: ''
    }
    // , equipped with both admin and editorial portals for managing user access and permissions, quoting articles from feeds, publishing to output channels for ingestion by other media streams, and much more
},{
    id:"2",
    name:"Reconcilliation OCR Module",
    category:"Software Development",
    description: "One of the top banking institutions in Malaysia requires a custom OCR module for scanned document to be developed & integrated into their reconciliation system.",
    img: {
        default:'/Assets/images/projects/OCR.webp', 
        classpath: 'brightness-75'
    }
    // This helps eliminate manual entry, consequently reducing errors and increasing productivity.
},{
    id:"3",
    name:"FIS BURSA EUAT",
    category:"Support & Maintenance",
    description: "BURSA Financial Information System (FIS) product for our client after the contract termination of the previous vendor. Despite having no prior knowledge of the system...",
    img: {
        default:'/Assets/images/projects/FIS.webp', 
        classpath: ''
    }
    // , we successfully resolved all issues related to it.
},{
    id:"4",
    name:"Election Result Monitoring System (ERMS)",
    category:"WebApp Development",
    description: "A General Election Results Monitoring System for a news agency in Malaysia, designed to update results from the field back to the live broadcast studio.",
    img: {
        default:'/Assets/images/projects/ERMS.webp', 
        classpath: 'object-top brightness-90'
    }
},{
    id:"5",
    name:"e-Commerce",
    category:"WebApp Development",
    description: "We provided an e-commerce solution to a local Malaysian brand, equipped with inventory management, payment gateway integration, blogs, email marketing features, and much more.",
    img: {
        default:'/Assets/images/projects/ECOMM.webp', 
        classpath: ''
    }
    
},{
    id:"6",
    name:"High Definition Sdn Bhd",
    category:"Business Page",
    description: "A simple business page for broadcasting system providers in Malaysia",
    img: {
        default:'/Assets/images/projects/HDTech.webp', 
        classpath: 'object-top brightness-90'
    }
},{
    id:"7",
    name:"Infrastructure Setup",
    category:"IT Infrastructure",
    description: "We successfully set up a NAS system with firewall security for office use, supplied servers capable of daily tasks and hosting their website, reducing cloud subscription costs",
    img: {
        default:'/Assets/images/projects/infra.webp', 
        classpath: 'brightness-90'
    }
}];

export default projectsData;