import consult from './Icons/mentorship.json';
import software from './Icons/software.json';
import integrate from './Icons/integrateSystem.json';
import webdesign from './Icons/webdesign.json';
import artist from './Icons/artist.json';
import legacy from './Icons/legacy.json';
import support from './Icons/techsupport.json';

const ConsultLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: consult,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const SoftwareLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: software,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const integrateLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: integrate,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const webLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: webdesign,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const artistLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: artist,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const legacyLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: legacy,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

const supportLottieIcon = {
    loop: true,
    autoplay: true,
    animationData: support,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
}

export {
    ConsultLottieIcon,
    SoftwareLottieIcon,
    integrateLottieIcon,
    webLottieIcon,
    artistLottieIcon,
    legacyLottieIcon,
    supportLottieIcon
}