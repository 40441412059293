import { useParams } from "react-router-dom";
import Header from "../../Components/All/Header";
import Question from "../../Components/Service/Question";
import { ContactUsCTA } from "../../Components/Home";

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, EffectCreative } from 'swiper/modules';

import { BsArrowRightCircle } from 'react-icons/bs'

import posts from "../../Components/Service/Data/ServiceList";
import WorkingHours from "./child/WorkingHours";
import ServicesList from "./child/ServicesList";

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import './ServiceDetail.css';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ServiceContainer = ({serviceData}) => {
	useGSAP(() => {
		const serviceSection = gsap.utils.toArray(".service-section");

		serviceSection.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
				repeat: 0,
				scrollTrigger: {
					trigger: section,
					once: true,
					start: "top 70%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 30%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none none", // Play on enter, reverse on leave
					// markers: true // Optional: show markers for debugging
				}
			})
		})
	}, []);
	
	return (
		<div className="detail-container flex flex-wrap sdesk1:w-full">
			<div className="img-wrap w-full aspect-video overflow-clip rounded-2xl smob1:!rounded-none mb-24">
				<img src={serviceData.banner} alt="banner" className="w-full h-full object-cover"/>
			</div>

			{serviceData.body.map((item, idx) => (
				<div key={idx} className="service-section w-full">{
					item.type === 'text'? 
					<div className={`${item.classname}`}>
						{
							(item.content.header !== undefined) ?
							<div>
								<span className={`block text-5xl font-bold mb-9 smob1:mb-4 tab1:text-4xl smob1:text-2xl ${item.headerClass !== undefined? item.headerClass : ''}`}>
									{item.content.header}
								</span>
							</div> : ''
						}
						
						{
							(item.content.paragraph !== undefined) && (item.content.paragraph !== '') ?
							<div className="!text-inherit">
								<div className="paragraph text-2xl leading-9 !text-inherit" dangerouslySetInnerHTML={{ __html: item.content.paragraph }}></div>
							</div> :
							''
						}

						{
							(item.content.list !== undefined) && (Array.isArray(item.content.list)) ?
							<div className="pointers-container !text-inherit">
								<ul className="w-full !text-inherit">
								{item.content.list.map((item, key) => (
									<li 
										key={key} 
										className="!text-inherit py-2 flex flex-row flex-wrap gap-4 smob1:!gap-2 items-start text-lgray1 text-xl smob1:text-base">
											<BsArrowRightCircle size="40" color="#208BF2" className="pt-2"/>
											<p className="w-[80%] !text-inherit" dangerouslySetInnerHTML={{ __html: item }}></p>
									</li>
								))}
								</ul>
							</div> :
							''
						}

						{
							(item.content.dlist !== undefined) && (Array.isArray(item.content.dlist)) ?
							<div className="pointers-container d-list !text-inherit">
								<ul className="w-1/2 !text-inherit">
									{
										item.content.dlist.reduce((m, k, i) => {
											if (i % 2 === 0) {
												m.push([k])
											} 
											return m
										}, []).map((grouped, index) => (
										<div key={index} className='row'>
											{
											grouped.map((data, j) =>
												<li 
													key={j} 
													className={`!text-inherit py-2 flex flex-row flex-wrap gap-4 smob1:!gap-2 items-${item.content.dlist_position !== undefined ? item.content.dlist_position:"center"} text-lgray1 text-xl smob1:text-base`}>
														<BsArrowRightCircle size="40" color="#208BF2" className="pt-2"/>
														<p className="!text-inherit w-[80%]" dangerouslySetInnerHTML={{ __html: data }}></p>
												</li>
												)
											}
										</div>
										))
									}
								</ul>

								<ul className="w-1/2 !text-inherit">
									{
										item.content.dlist.reduce((m, k, i) => {
											if (i % 2 > 0) {
												m.push([k])
											} 
											return m
										}, []).map((grouped, index) => (
										<div key={index} className='row'>
											{
											grouped.map((data, j) =>
												<li 
													key={j} 
													className={`!text-inherit py-2 flex flex-row flex-wrap gap-4 smob1:!gap-2 items-${item.content.dlist_position !== undefined ? item.content.dlist_position:"center"} text-lgray1 text-xl smob1:text-base`}>
														<BsArrowRightCircle size="40" color="#208BF2" className="pt-2"/>
														<p className="!text-inherit w-[80%]" dangerouslySetInnerHTML={{ __html: data }}></p>
												</li>
												)
											}
										</div>
										))
									}
								</ul>
							</div> :
							''
						}
					</div>

					: item.type === 'imageAndPoint'
					? <div className={`${item.classname}`}>
						<div className="image-pointer-container flex flex-wrap flex-row rounded-[30px] smob1:!rounded-none overflow-hidden bg-gray-bg-3 tab1:flex-col">
							<div className="image-container relative w-1/2 rounded-[30px] smob1:!rounded-none overflow-clip tab1:w-full tab1:aspect-video">
								{
									(item.content.video !== undefined) && (item.content.video !== '') ?
									<div className="w-full h-full z-0" dangerouslySetInnerHTML={{
										__html: 
										"<video class=\"absolute w-full h-full object-cover top-0\" id=\"bannerVideo\" preload=\"auto\" playsinline autoplay muted loop>" +
											"<source src=" + item.content.video + " type=\"video/webm\" />" +
										"</video>"
									}}/>
									:
									''
								}

								{
									(item.content.image !== undefined) && (item.content.image !== '') ?
									<img src={item.content.image} alt={item.content.header} className={`absolute z-10 top-0 w-full h-full ${item.content.imagefit !== undefined ? item.content.imagefit : "object-cover" }`}/>
									:
									''
								}
								
							</div>
						
							{
								(item.content.list !== undefined) && (Array.isArray(item.content.list)) ?
								<div className="pointers-container">
									{ (item.content.header !== undefined) && (item.content.header !== '') ?
										<span className={`title ${item.content.headerClass !== undefined ? item.content.headerClass : "" } `}>
											{item.content.header}
										</span> :
										''
									}
									
									<ul className="w-full">
									{item.content.list.map((data, key) => (
										<li 
											key={key} 
											className={`py-2 flex flex-row flex-wrap gap-4 smob1:!gap-2 ${item.content.itemFlex !== undefined ? item.content.itemFlex : "items-start"} text-lgray1 text-xl smob1:text-base`}>
												{
													item.content.list.length > 1 ?
														item.content.listIcons !== undefined ?
															item.content.listIcons :
															<BsArrowRightCircle size="40" color="#208BF2" className="pt-2"/>
														: 
														''
												}
												
												{/* <Icon icon="iconamoon:search" /> */}
												<p className="w-[80%] mx-auto smob1:!w-[100%]" dangerouslySetInnerHTML={{ __html: data }}></p>
										</li>
									))}
									</ul>
								</div> :
								''
							}
						</div>
					</div> 

					: item.type === "imageList" ?
					<div>
						<div className="image-col-container flex flex-wrap my-9 justify-between smob1:mt-9 smob1:mb-4">
							{ item.images.map((item, idx) => (
								
								// return(
								<div key={idx} className="image-col-wrap w-[48%] tab1:w-full">
									<div className="rounded-[30px] overflow-clip w-full aspect-square">
										<img src={item} alt={serviceData.title} className="object-cover w-full h-full"/>
									</div>
								</div>
								// )
							))
							}
						</div>
					</div>

					: item.type === "component" ?
					<div className={`${item.classname}`}>
						<div className="component-container content-wrapper !m-0 !p-0 ">
							{ item.component }
						</div>
					</div> 

					: item.type === "swiper" ?
					<div className={`${item.classname}`}>
						<div className="swiper-container content-wrapper !m-0 !p-0 ">
							<Swiper
								effect={'coverflow'}
								grabCursor={true}
								centeredSlides={false}
								direction="horizontal"
								loop={true}
								// slidesPerView={3}
								breakpoints={{
									640: {
									  slidesPerView: 1,
									},
									768: {
									  slidesPerView: 2,
									},
									1024: {
									  slidesPerView: 3,
									},
								}}
								coverflowEffect={{
									rotate: 50,
									stretch: 0,
									depth: 100,
									modifier: 1,
									slideShadows: true,
								}}
								pagination={true}
								modules={[EffectCoverflow, EffectCreative, Pagination]}
								className="service-detail-swiper"
								>
									{ item.content.data.map((data, idx) => (
									<SwiperSlide>
										<div className="w-full h-full relative">
											{
												(data.title !== undefined) && ((data.title !== '')) ?
													<div className={`swiper-title absolute ${data.swiperTextClass !== undefined && data.swiperTextClass !== '' ? data.swiperTextClass : ''}`}>
														{ data.title }
													</div> :
													''
											}
											<img className="object-cover w-full h-full" src={data.img} title={data.title} alt={data.title}/>
										</div>
										
									</SwiperSlide>
									))
									}
							</Swiper>
						</div>
					</div> 
					: ''
				}</div>
			))}

			<ContactUsCTA title="Want to Learn More ?" btnTitle="Let's Get in Touch"/>
			
			{
				(serviceData.questions !== undefined) && (serviceData.questions.length > 0)?
				<div className="faq-container w-full mt-9 smob1:mt-0">
					<div>
						{serviceData.questions.map((item) => (
							<Question item={item}/>
						))}
					</div>
				</div> :
				''
			}
		</div>
	);
}

const ServiceDetail = () => {
	const { serviceId } = useParams();

	const serviceData = posts.filter((post) => {
		return post.id === serviceId
	})[0];

	const services = serviceData.serviceList !== undefined ? 
		serviceData.serviceList: 
		[
			{
				name: 'Technology Solution',
				link: '1',
			},
			{
				name: 'Technology Solution',
				link: '2',
			},
			{
				name: 'Technology Solution',
				link: '3',
			}
		]

	return (
		<div className="serviceDetail-page w-full">
			<Header title={serviceData.shortTitle} path={'Services >'}/>

			<div className="serviceDetail-container">
				<div className="w-full flex-wrap flex sdesk1:flex-col justify-center gap-8 tab1:gap-3">
					<ServiceContainer serviceData={serviceData.content}/>

					<div className="sideInfo-container">
						<ServicesList services={services}/>
						<WorkingHours/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceDetail;

