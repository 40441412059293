import { 
    Banner1,
	ServiceContainer,
	ContactUsCTA,
	TeamMemberContainer,
	ProjectContainer,
	BlogsContainer,
	ClientContainer
} from '../../Components/Home'

const Home = () => {
    return(
        <div className='home-page page-container'>
            <Banner1 />
            <ServiceContainer />
            <ProjectContainer />
            <ContactUsCTA />
            <TeamMemberContainer />
            <ClientContainer />
            <BlogsContainer />
            
        </div>
    )
};

export default Home;