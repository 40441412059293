import Header from "../../Components/All/Header";
import Menu from "../../Components/Service/Menu";
import Contact from "../../Components/Service/Contact";
import Blog from "../../Components/Service/Blog";
import blogs from "./data/services";

import './Service.css';

const Service = () => {
	return (
		<div className="service-page overflow-hidden">
			<Header title="Service"></Header>
			<Menu></Menu>
			<Contact></Contact>
			<Blog items={blogs}></Blog>
		</div>
	);
}
export default Service;